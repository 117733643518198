import * as React from 'react'
import { Link } from 'gatsby'
import CategorySlider from './sliders/CategorySlider'

const ShopJewelryCategory = () => {
  return (
    <section className="py-10  w-full flex flex-col items-center">
      <h2>
        <Link className="underline-title " to={'/jewelry/'}>
          Shop Estate Jewelry By Category
        </Link>
      </h2>
      <CategorySlider className={'max-w-[1366px] mx-auto'} type="jewelry" />
      <p className="py-5 leading-loose max-w-[800px] mx-auto">
        Choose with confidence knowing every option has been expertly verified and certified and is
        covered by our exclusive in-house warranty. If you have any issues or concerns with one of
        the luxury timepieces we offer, our skilled watch experts stand behind each and every watch
        we sell. In addition to our luxury watches, you will also find an array of beautiful jewelry
        from the most esteemed designers on the scene. Whether looking for a stunning pair of
        classic earrings or a cutting-edge necklace perfect as a statement piece for your next
        black-tie event, our selection of fine jewelry has something sure to excite your tastes and
        meet your every need. With a firm grasp on what our valued customers are seeking, we are
        confident you will find the right choice for your personal style evolution. We are committed
        to delivering outstanding customer service from start to finish. Please contact us with any
        questions you may have, and we will gladly assist you further on the matter.
      </p>

      <div className="flex w-full justify-center">
        <Link
          to="/jewelry/"
          className="rounded-md border-2 border-red-700 bg-red-700 px-5 py-3 text-center font-bold uppercase text-white duration-200 hover:-translate-y-1 hover:border-red-700 hover:bg-white hover:text-red-700 hover:shadow-lg"
        >
          <h3> Shop All Estate Jewelry</h3>
        </Link>
      </div>
    </section>
  )
}

export default ShopJewelryCategory

import * as React from 'react'
import { useEffect, useState } from 'react'
import { IconChevronLeft, IconChevronRight } from '../Icons'
import TailwindScreens, { TailwindScreensKeys } from '../../helpers/tailwind'
import PropTypes from 'prop-types'

const Carousel = ({
  children,
  infiniteLoop,
  className,
  scrollStep,
  slides,
  startItem,
  onSlidesChange,
  onCurrentChange,
  gap,
}) => {
  slides = slides || { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }
  const getStep = () => {
    return (scrollStep || 1) > show ? show : scrollStep || 1
  }
  const [show, setShow] = useState(slides?.xs || 1)
  const [currentIndex, setCurrentIndex] = useState(
    Number((infiniteLoop ? show : 0) + (startItem || 0))
  )
  const [length, setLength] = useState(children.length)
  const [isRepeating, setIsRepeating] = useState(infiniteLoop && children.length > show)
  const [transitionEnabled, setTransitionEnabled] = useState(true)
  const [touchPosition, setTouchPosition] = useState(null)
  // const carouselContent =

  useEffect(() => {
    window.mm = window.mm || {}
    const isMatch = size => {
      return (window.mm[size] || window.matchMedia(TailwindScreens[size])).matches
    }

    function handleScreenUpdate() {
      let screenSize = 'xs'
      TailwindScreensKeys.forEach(size => {
        if (slides[size] && isMatch(size)) {
          screenSize = size
        }
      })
      const val = slides[screenSize] || 1
      if (onSlidesChange) {
        onSlidesChange(val)
      }
      setShow(val)
    }

    TailwindScreensKeys.forEach(size => {
      window.mm[size] = window.matchMedia(TailwindScreens[size])
      if (slides[size]) {
        window.mm[size].addEventListener('change', handleScreenUpdate, { passive: true })
      }
    })
    handleScreenUpdate()
    // clean up
    return () => {
      TailwindScreensKeys.forEach(size => {
        window.mm[size].removeEventListener('change', handleScreenUpdate)
      })
    }
  }, [slides, onSlidesChange, length])

  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length)
    setIsRepeating(infiniteLoop && children.length > show)
  }, [children, infiniteLoop, show])

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true)
      }
    }
  }, [currentIndex, isRepeating, show, length])

  const next = () => {
    if (isRepeating || currentIndex < length - show) {
      let step = Math.max(1, Math.min(getStep(), length - show - currentIndex))
      setCurrentIndex(prevState => {
        if (onCurrentChange) {
          onCurrentChange(prevState + step - (infiniteLoop ? show : 0))
        }
        return prevState + step
      })
    }
  }

  const prev = () => {
    if (isRepeating || currentIndex > 0) {
      let step = Math.max(1, Math.min(getStep(), currentIndex))
      setCurrentIndex(prevState => {
        if (onCurrentChange) {
          onCurrentChange(prevState - step - (infiniteLoop ? show : 0))
        }
        return prevState - step
      })
    }
  }

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = e => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      next()
    }

    if (diff < -5) {
      prev()
    }

    setTouchPosition(null)
  }

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const renderExtraPrev = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index])
    }
    output.reverse()
    return output
  }

  const renderExtraNext = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[index])
    }
    return output
  }

  return (
    <div className="max-w-[1366px] w-full flex flex-col lg:px-10 relative">
      <div className="flex w-full">
        {(isRepeating || currentIndex > 0) && (
          <button
            onClick={prev}
            className="h-24 w-15 z-20 bg-gray-300 bg-opacity-70 absolute top-1/3 left-1 hover:text-red-700 duration-300 hover:-left-1"
          >
            <span className="sr-only">Previous</span>
            <IconChevronLeft className={'text-black text-2xl'} />
          </button>
        )}
        <div
          className="overflow-hidden w-full h-full"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel show-${show}-gap-${gap} item-${currentIndex} ${
              transitionEnabled ? '' : 'no-transition'
            } ${className}`}
            onTransitionEnd={() => handleTransitionEnd()}
          >
            {length > show && isRepeating && renderExtraPrev()}
            {children}
            {length > show && isRepeating && renderExtraNext()}
          </div>
        </div>
        {(isRepeating || currentIndex < length - show) && (
          <button
            onClick={next}
            className="h-24 w-15 z-20 bg-gray-300 bg-opacity-70 absolute top-1/3 right-1 hover:text-red-700 duration-300 hover:-right-1"
          >
            <span className="sr-only">Next</span>
            <IconChevronRight className={'text-black text-2xl'} />
          </button>
        )}
      </div>
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  slides: PropTypes.object,
  gap: PropTypes.number,
  infiniteLoop: PropTypes.bool,
  onCurrentChange: PropTypes.func,
  onSlidesChange: PropTypes.func,
  scrollStep: PropTypes.number,
  startItem: PropTypes.number,
}

export default Carousel
